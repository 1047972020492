import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core"
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class CurrencyRateService {
    usd = 33.55
    euro = 36.67
    constructor(private http:HttpClient) {}

    tltoUsd(price:number): number {
        return parseFloat((price / this.usd).toFixed(2))
    }

    tltoEuro(price:number): number {
        return parseFloat((price / this.euro).toFixed(2))
    }

    usdtoTl(price:number): number {
        return parseFloat((price * this.usd).toFixed(2))
    }

    eurotoTl(price:number): number {
        return parseFloat((price * this.euro).toFixed(2))
    }

    updateCurrancyRate(): Observable<any> {
        return this.http.get<any>('https://shoppingapi.shippn.com/api/currencyRates',{params:{currencyCode:'USD'}});
    }

    updateCurrancyRateEuro(): Observable<any> {
        return this.http.get<any>('https://shoppingapi.shippn.com/api/currencyRates',{params:{currencyCode:'EUR'}});
    }

    setTryRate(tryRate:number) {
        this.usd = tryRate
    }

    setTryRateEuro(tryRate:number) {
        this.euro = tryRate
    }

    getCurrencyUsd():number {
        return this.usd
    }

    getCurrencyEuro():number {
        return this.euro
    }
}